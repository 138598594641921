import JQuery, { cssNumber } from "jquery";
import { Calendar } from "@fullcalendar/core";

import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";

import bootstrapPlugin, { BootstrapTheme } from "@fullcalendar/bootstrap";

// Load locales
import deLocale from "@fullcalendar/core/locales/de";
import frLocale from "@fullcalendar/core/locales/fr";

// Load CSS
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/list/main.css";
import "@fullcalendar/timegrid/main.css";

BootstrapTheme.prototype.baseIconClass = "far";

import { Controller } from "stimulus";
import { request } from "controllers/api";

export default class extends Controller {
  initialize() {
    this.calendar = this.initializeFullCalendar();
  }

  connect() {
    this.calendar.render();
  }

  disconnect() {
    this.calendar.destroy();
  }

  initializeFullCalendar() {
    return new Calendar(this.element, {
      plugins: [dayGridPlugin, timeGridPlugin, bootstrapPlugin],
      defaultView: "dayGridMonth",
      themeSystem: "bootstrap",
      locales: [deLocale, frLocale],
      header: {
        left: "title",
        center: "",
        right: "today dayGridMonth,timeGridWeek prev,next",
      },
      eventSources: this.sources,
      bootstrapFontAwesome: {
        dayGridMonth: "fa-calendar-alt",
        timeGridWeek: "fa-calendar-week",
        listQuarterYear: "fa-list",
      },
      locale: this.data.get('locale'),
      events: this.data.get('url'),
      eventClick: (info) => this.handleEventClick(info),
      eventSourceFailure(error) {
        if (error.xhr && error.xhr.status === 401) {
          window.location.reload();
        }
      },
    });
  }

  handleEventClick({ event, jsEvent }) {
    jsEvent.preventDefault();
    this.fetchEvent(event.url);
  }

  fetchEvent(url) {
    const headers = { "X-Requested-With": "XMLHttpRequest" };
    request(url, { headers }).then((response) =>
      response.ok
        ? response.text().then((html) => this.showEventModal(html))
        : this.handleError(response)
    );
  }

  showEventModal(html) {
    const modalContainerEl = JQuery("#event-modal-container");
    modalContainerEl.html(html);

    const modalEl = JQuery("#event-modal");
    modalEl
      .modal({
        show: true,
      })
      .on("hidden.bs.modal", () => {
        modalContainerEl.empty();
      });
  }

  handleError(response) {
    console.log(response);
  }
}
