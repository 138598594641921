import ApplicationController from "controllers/application_controller";
import { debounce } from "lodash-es";

export default class extends ApplicationController {
  static targets = ["query"];

  connect() {
    super.connect();
    this.perform = debounce(this.perform, 500);
  }

  beforePerform() {
    this.element.classList.add("search-component--loading");
    this.queryTarget.readOnly = true;
  }

  perform() {
    if (this.queryTarget.value.length > 2) {
      this.stimulate("Search#perform", this.queryTarget);
    }
  }

  afterPerform() {
    this.element.classList.remove("search-component--loading");
    this.queryTarget.readOnly = false;
    this.queryTarget.focus();
  }

  reset(event) {
    event.preventDefault();

    this.queryTarget.value = "";
    this.perform();
    this.perform.flush();
  }
}
