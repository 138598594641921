function request(url, options = {}) {
  return fetch(url, options).then((response) => {
    if (response.redirected) {
      window.location.href = response.url;
      return;
    } else if ([401].includes(response.status)) {
      window.location.reload();
      return;
    }

    return response;
  });
}

export { request };
