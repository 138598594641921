import { Controller } from "stimulus";
import JQuery from "jquery";

export default class extends Controller {
  MAX_CATEGORIES = 3

  connect() {
    JQuery(this.element).on('change', 'input[type=checkbox]', (event) => {
      this.disableOnMaxSelected()
    })
  }

  disableOnMaxSelected() {
    const checkboxes = JQuery(this.element).find('input[type=checkbox]')
    const checked = checkboxes.filter(':checked')
    const unchecked = checkboxes.filter(':not(:checked)')
    if (checked.length >= 3) {
      unchecked.prop("disabled", true)
    } else {
      unchecked.prop("disabled", false)
    }
  }

  search(event) {
    const query = event.target.value

    JQuery(this.element).find('.category').each(function () {
      const visible = JQuery(this).html().toLowerCase().includes(query.toLowerCase())
      JQuery(this).toggle(visible);
    })
  }
}
